'use client'

import { message } from 'antd'
import { getCookie } from 'cookies-next'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import Chatbot from '@/components/Chatbot'
import ChatLayout from '@/components/ChatLayout'
import Matcher from '@/components/Matcher'

import { switchLanguage } from '@/i18n'

const Home = () => {
  const { error, isLoading, user } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const { settings } = useDbSettings()
  const { isMatcherOpen } = useGrantApplicationState()

  useEffect(() => {
    if (error) {
      router.replace(
        `/login${pathname ? '?redirect=' + encodeURIComponent(pathname) : ''}`
      )
    }
    if (
      settings?.requireSubscription &&
      user &&
      !user.subscribed &&
      user.role !== 'ADMIN'
    ) {
      message.error('You need complete your signup first.')
      router.replace('/landing')
    }
  }, [error, user, settings])

  // Set language from cookie
  const lang = getCookie('locale')
  if (lang) {
    setTimeout(() => switchLanguage(lang))
  }

  if (
    isLoading ||
    error ||
    (settings?.requireSubscription &&
      user &&
      !user.subscribed &&
      user.role !== 'ADMIN')
  ) {
    return null
  }

  return (
    <ChatLayout>
      {isMatcherOpen ? <Matcher /> : <Chatbot indexPage />}
    </ChatLayout>
  )
}

export default Home
